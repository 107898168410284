<template>
    <section class="height-100-vh iq-main-slider">
      <video class="video d-block" width="100%" height="100%" controls>
         <source src="../../../../assets/video/trailer.mp4" type="video/mp4">
      </video>
   </section>
</template>
<script>
export default {
  name: 'Video',
  components: {
  },
  mounted () {
  }
}
</script>
